import React from "react";
import Layout from "../components/layout";
import InfoBox from "../components/infoBox";
import NoteBox from "../components/noteBox";
import Community from "../components/community";
import SEO from "../components/seo";

import cLogo from "../images/c-logo.svg";
import cppLogo from "../images/cpp-logo.svg";
import pythonLogo from "../images/python-logo.svg";
import goLogo from "../images/go-logo.svg";
import rustLogo from "../images/rust-logo.svg";

import { createUseStyles } from "../styling";

const useStyles = createUseStyles({
  docBody: {
    paddingRight: "36px",
    paddingLeft: "36px",
    "& p": {
      fontSize: "15px",
      lineHeight: "26px",
    },
    paddingBottom: "20px",
  },
  dynamic: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "25px",
    marginTop: "25px",
    "& div:nth-child(2)": {
      marginLeft: "17px",
    },
    "& div:nth-child(3)": {
      marginLeft: "17px",
    },
  },
  quickstartWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  quickstart: {
    border: "1px solid #EEF0F4",
    flex: "1 1 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    marginRight: "25px",
    padding: "15px",
    "&::last-of-type": {
      marginRight: "0",
    },
    "&:hover": {
      boxShadow: "0px 0px 20px rgb(181 192 207 / 22%)",
      transition: "box-shadow 200ms",
    },
  },
  "@media screen and (max-width: 1075px)": {
    docBody: {
      paddingRight: "17px",
      paddingLeft: "17px",
    },
  },
  "@media screen and (max-width: 750px)": {
    dynamic: {
      flexDirection: "column",
      marginBottom: "23px",
      "& div": {
        marginBottom: "15px",
      },
      "& div:nth-child(2)": {
        marginLeft: "0px",
      },
      "& div:nth-child(3)": {
        marginLeft: "0px",
      },
    },
  },
});

const IndexPage = ({ location }: { location: Location }) => {
  const classes = useStyles();
  return (
    <Layout location={location}>
      <SEO title={"Home"} lang={"en"} />
      <div className={classes.docBody}>
        <h1 className={classes.h1}>Documentation</h1>
        <p>
          Fuzzbuzz is a{" "}
          <a
            href="https://blog.fuzzbuzz.io/what-is-fuzz-testing/"
            target="_blank"
            rel="noreferrer"
          >
            fuzz testing
          </a>{" "}
          platform. We make it trivial for developers to add fuzz tests to their
          code and run them in CI/CD, helping them catch critical bugs and
          vulnerabilities before they hit production.
        </p>
        <p>
          <i>
            Note: Our docs are still a work in progress, and there are likely
            things we've mised - please join our{" "}
            <a href="https://discord.fuzzbuzz.io" target="_blank">
              Discord channel
            </a>{" "}
            if you've got any questions!
          </i>
        </p>
        <h2>How Fuzzbuzz works</h2>
        <p>
          Fuzzbuzz completely integrates into <b>your</b> environment, following
          you from the terminal to CI/CD. The entire workflow can be summarized
          in 4 steps:
          <ol>
            <li>
              <b>Write a fuzz test</b> in your environment. Use your own IDE,
              terminal, build tools.{" "}
            </li>
            <li>
              <b>Push to CI/CD</b> and Fuzzbuzz will automatically start running
              your fuzz tests against your latest code changes.
            </li>
            <li>
              <b>Get notified when bugs are found</b> through Slack, GitHub or
              email.
            </li>
            <li>
              <b>Catch regressions</b> as new changes are automatically tested
              and compared to previous runs.
            </li>
          </ol>
        </p>
        <h2>Quick Start</h2>
        <p>
          If you're already familiar with fuzz testing, or prefer learning with
          hands-on tutorials, you can get started by selecting your language
          below:
        </p>
        <div className={classes.quickstartWrapper}>
          <a
            className={classes.quickstart}
            href="/docs/getting-started-in-c-or-c++"
          >
            <img src={cppLogo} height="65px"></img>
          </a>
          <a
            className={classes.quickstart}
            href="/docs/getting-started-in-c-or-c++"
          >
            <img src={cLogo} height="65px"></img>
          </a>
          <a
            className={classes.quickstart}
            href="/docs/getting-started-in-python"
          >
            <img src={pythonLogo} height="65px"></img>
          </a>
          <a className={classes.quickstart} href="/docs/getting-started-in-go">
            <img src={goLogo} height="40px"></img>
          </a>
          <a
            className={classes.quickstart}
            href="/docs/getting-started-in-rust"
          >
            <img src={rustLogo} height="65px"></img>
          </a>
        </div>
        <p>
          Is your language missing?
          <a
            href="https://fuzzbuzz.typeform.com/to/ieAmr75K"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Get notified when we add it.
          </a>
        </p>
        <h2>Slow Start</h2>
        <p>
          If this is your first time hearing about fuzz testing, or want to get
          a more theoretical understanding of how it works, start here:
        </p>
        <div className={classes.dynamic}>
          <InfoBox
            title="Fuzzing 101"
            button="Read Blog Post"
            to="https://blog.fuzzbuzz.io/what-is-fuzz-testing/"
            content="8 minute deep-dive into how fuzz testing works"
          />
          <InfoBox
            title="Platform Overview"
            button="Learn More"
            to="/docs/platform-overview"
            content="Learn how Fuzzbuzz is built to make developers' lives easier."
          />
          <InfoBox
            title="Types of Bugs"
            button="Learn More"
            to="/docs/bug-types"
            content="Automatically detect these types of bugs with a single fuzz test"
          />
        </div>
        <Community />
      </div>
    </Layout>
  );
};

export default IndexPage;
